import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ToastContainer } from "react-toastify";
import Sidebar from "../../components/admin/sidebar/Sidebar";
import useDash from "../../assets/css/dash.module.css";
import { FontColor, FourGround } from "../../assets/js/theme";
import moment from "moment";
import {
  ArrowClockwise,
  FilterCircle,
  PrinterFill,
} from "react-bootstrap-icons";
import Footer from "../../components/admin/footer/Footer";
import "../Customer/customer.css";
import "./report.css";

import { useDispatch, useSelector } from "react-redux";
import { getCollectorBill } from "../../features/apiCalls";
import FormatNumber from "../../components/common/NumberFormat";
import Table from "../../components/table/Table";
import { useTranslation } from "react-i18next";
import Loader from "../../components/common/Loader";
import DatePicker from "react-datepicker";
import PrintReport from "./CollectorReportPDF";
import ReactToPrint from "react-to-print";
import { Accordion } from "react-bootstrap";
import ReportView from "../../pages/report/modal/ReportView";

export default function CollectorReport() {
  const { t } = useTranslation();
  const componentRef = useRef();
  //   const allArea = useSelector(state => state.area.area);
  const [allArea, setAreas] = useState([]);

  // get user information
  const userData = useSelector((state) => state.persistedReducer.auth.userData);

  const collectorArea = useSelector(
    (state) => state.persistedReducer.auth.currentUser?.collector.areas
  );

  const allBills = useSelector((state) => state.collector.collectorBill);

  var today = new Date();
  var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  firstDay.setHours(0, 0, 0, 0);
  today.setHours(23, 59, 59, 999);

  // filter date state
  const [filterDate, setFilterDate] = useState(firstDay);

  // curr & priv date state
  const [dateStart, setStartDate] = useState(new Date());
  const [dateEnd, setEndDate] = useState(new Date());

  var selectDate = new Date(filterDate.getFullYear(), filterDate.getMonth(), 1);
  var lastDate = new Date(
    filterDate.getFullYear(),
    filterDate.getMonth() + 1,
    0
  );

  const [singleArea, setArea] = useState({});
  const [subAreaIds, setSubArea] = useState([]);
  const [mainData, setMainData] = useState(allBills);
  const [isLoading, setIsLoading] = useState(false);

  // filter Accordion handle state
  const [activeKeys, setActiveKeys] = useState("");

  // report note view id
  const [viewId, setViewId] = useState();

  // modal close hadler
  const [modalStatus, setModalStatus] = useState("");
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  // reload handler
  const reloadHandler = () => {
    getCollectorBill(
      dispatch,
      filterDate.getFullYear(),
      filterDate.getMonth() + 1,
      setIsLoading
    );
  };

  useEffect(() => {
    let areas = [];

    collectorArea?.map((item) => {
      let area = {
        id: item?.area?.id,
        name: item.area?.name,
        subAreas: [
          {
            id: item.id,
            name: item.name,
          },
        ],
      };

      let found = areas?.find((area) => area.id === item.area?.id);
      if (found) {
        found.subAreas.push({ id: item.id, name: item.name });

        return (areas[areas.findIndex((item) => item.id === found.id)] = found);
      } else {
        return areas.push(area);
      }
    });
    setAreas(areas);
  }, [collectorArea]);

  useEffect(() => {
    setStartDate(selectDate);

    if (lastDate.getMonth() + 1 === today.getMonth() + 1) {
      setEndDate(today);
    } else {
      setEndDate(lastDate);
    }

    filterDate.getMonth() + 1 &&
      getCollectorBill(
        dispatch,
        filterDate.getFullYear(),
        filterDate.getMonth() + 1,
        setIsLoading
      );
  }, [filterDate]);

  useEffect(() => {
    setMainData(allBills);
  }, [allBills]);

  const onChangeArea = (param) => {
    let area = JSON.parse(param);
    setArea(area);

    if (
      area &&
      Object.keys(area).length === 0 &&
      Object.getPrototypeOf(area) === Object.prototype
    ) {
      setSubArea([]);
    } else {
      let subAreaIds = [];

      area?.subAreas.map((sub) => subAreaIds.push(sub.id));

      setSubArea(subAreaIds);
    }
  };

  const onChangeSubArea = (id) => {
    if (!id) {
      let subAreaIds = [];
      singleArea?.subAreas.map((sub) => subAreaIds.push(sub.id));

      setSubArea(subAreaIds);
    } else {
      setSubArea([id]);
    }
  };

  const onClickFilter = () => {
    let arr = allBills;

    if (subAreaIds.length) {
      arr = allBills?.filter((bill) =>
        subAreaIds?.includes(bill?.customer?.subArea)
      );
    }

    arr = arr.filter(
      (item) =>
        new Date(moment(item.createdAt).format("YYYY-MM-DD")).getTime() >=
          new Date(moment(dateStart).format("YYYY-MM-DD")).getTime() &&
        new Date(moment(item.createdAt).format("YYYY-MM-DD")).getTime() <=
          new Date(moment(dateEnd).format("YYYY-MM-DD")).getTime()
    );

    setMainData(arr);
  };

  const addAllBills = useCallback(() => {
    var count = 0;
    mainData?.forEach((item) => {
      count = count + item.amount;
    });
    return FormatNumber(count);
  }, [mainData]);

  //billing data show columns
  const columns = useMemo(
    () => [
      {
        width: "8%",
        Header: t("id"),
        accessor: "customer.customerId",
      },
      {
        width: "12%",
        Header: t("name"),
        accessor: "customer.name",
      },
      {
        width: "11%",
        Header: t("package"),
        accessor: "package",
      },
      {
        width: "11%",
        Header: t("bill"),
        accessor: "amount",
      },
      {
        width: "11%",
        Header: t("billType"),
        accessor: "billType",
      },
      {
        width: "11%",
        Header: t("medium"),
        accessor: "medium",
      },
      {
        width: "12%",
        Header: t("note"),
        accessor: (data) => {
          return {
            id: data.id,
            note: data.note,
            start: data.start,
            end: data.end,
            month: data.month,
          };
        },
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <p>
                {value.note && value.note.slice(0, 15)}{" "}
                <span>{value?.note && value?.note?.length > 15 && "..."}</span>
              </p>
              {value?.start && value?.end && (
                <span className="badge bg-secondary">
                  {moment(value.start).format("YYYY/MM/DD")}
                  {moment(value.end).format("YYYY/MM/DD")}
                </span>
              )}
              <p>
                {value?.month && value.month.slice(0, 15)}{" "}
                <span>
                  {value?.month && value?.month?.length > 15 && "..."}
                </span>
              </p>
              <span
                className="see_more"
                onClick={() => {
                  setViewId(value?.id);
                  setModalStatus("reportView");
                  setShow(true);
                }}
              >
                ...See More
              </span>
            </>
          );
        },
      },
      {
        width: "12%",
        Header: t("date"),
        accessor: "createdAt",
        Cell: ({ cell: { value } }) => {
          return moment(value).format("YYYY/MM/DD hh:mm a");
        },
      },
    ],
    [t, allBills]
  );

  const customComponent = (
    <div style={{ fontSize: "18px" }}>
      {t("totalBill")} {addAllBills()} {t("tk")}
    </div>
  );

  // const areaName = singleArea?.subAreas?.find((item) => item.id === subAreaIds);

  // const filterData = {
  //   area: areaName?.name ? areaName.name : t("all"),
  //   subArea: singleArea?.subAreas?.name
  //     ? singleArea?.subAreas.name
  //     : t("allSubArea"),
  //   startDate: moment(dateStart).format("YYYY-MM-DD"),
  //   endDate: moment(dateEnd).format("YYYY-MM-DD"),
  //   totalBill: mainData.reduce((prev, current) => prev + current.amount, 0),
  // };

  return (
    <>
      <Sidebar />
      <ToastContainer position="top-right" theme="colored" />

      <div className={useDash.dashboardWrapper}>
        <div className="container-fluied collector">
          <div className="container">
            <FontColor>
              <FourGround>
                <div className="collectorTitle d-flex justify-content-between px-4">
                  <div>{t("billReport")}</div>

                  <div className="d-flex justify-content-center align-items-center">
                    <div
                      onClick={() => {
                        if (!activeKeys) {
                          setActiveKeys("filter");
                        } else {
                          setActiveKeys("");
                        }
                      }}
                      title={t("filter")}
                    >
                      <FilterCircle className="addcutmButton" />
                    </div>

                    <div className="reloadBtn">
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <ArrowClockwise
                          className="arrowClock"
                          title={t("refresh")}
                          onClick={() => reloadHandler()}
                        ></ArrowClockwise>
                      )}
                    </div>

                    <ReactToPrint
                      documentTitle={t("billReport")}
                      trigger={() => (
                        <PrinterFill className="addcutmButton border-0" />
                      )}
                      content={() => componentRef.current}
                    />

                    {/* print report */}
                    <div style={{ display: "none" }}>
                      <PrintReport
                        // filterData={filterData}
                        currentCustomers={mainData}
                        ref={componentRef}
                      />
                    </div>
                  </div>
                </div>
              </FourGround>

              <FourGround>
                <div className="mt-2">
                  <Accordion alwaysOpen activeKey={activeKeys}>
                    <Accordion.Item eventKey="filter">
                      <Accordion.Body>
                        <div className="displayGrid6">
                          <div>
                            <DatePicker
                              className="form-control mw-100 mt-0"
                              selected={filterDate}
                              onChange={(date) => setFilterDate(date)}
                              dateFormat="MMM-yyyy"
                              showMonthYearPicker
                              showFullMonthYearPicker
                              maxDate={new Date()}
                              minDate={new Date(userData?.createdAt)}
                            />
                          </div>

                          <select
                            className="form-select mt-0"
                            onChange={(e) => onChangeArea(e.target.value)}
                          >
                            <option value={JSON.stringify({})} defaultValue>
                              {t("allArea")}{" "}
                            </option>
                            {allArea.map((area, key) => (
                              <option key={key} value={JSON.stringify(area)}>
                                {area.name}
                              </option>
                            ))}
                          </select>

                          <select
                            className="form-select mt-0"
                            onChange={(e) => onChangeSubArea(e.target.value)}
                          >
                            <option value="" defaultValue>
                              {t("allSubArea")}{" "}
                            </option>
                            {singleArea?.subAreas?.map((sub, key) => (
                              <option key={key} value={sub.id}>
                                {sub.name}
                              </option>
                            ))}
                          </select>

                          <div>
                            <DatePicker
                              className="form-control mw-100 mt-0"
                              selected={dateStart}
                              onChange={(date) => setStartDate(date)}
                              dateFormat="MMM dd yyyy"
                              minDate={selectDate}
                              maxDate={
                                lastDate.getMonth() + 1 === today.getMonth() + 1
                                  ? today
                                  : lastDate
                              }
                              placeholderText={t("selectBillDate")}
                            />
                          </div>
                          <div>
                            <DatePicker
                              className="form-control mw-100 mt-0"
                              selected={dateEnd}
                              onChange={(date) => setEndDate(date)}
                              dateFormat="MMM dd yyyy"
                              minDate={selectDate}
                              maxDate={
                                lastDate.getMonth() + 1 === today.getMonth() + 1
                                  ? today
                                  : lastDate
                              }
                              placeholderText={t("selectBillDate")}
                            />
                          </div>
                          <button
                            className="btn btn-outline-primary w-140 mt-0"
                            type="button"
                            onClick={onClickFilter}
                          >
                            {t("filter")}
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <div className="collectorWrapper pb-2">
                    <div className="addCollector">
                      <div className="table-section">
                        <Table
                          customComponent={customComponent}
                          isLoading={isLoading}
                          data={mainData}
                          columns={columns}
                        ></Table>
                      </div>
                    </div>
                  </div>
                </div>
              </FourGround>
              <Footer />
            </FontColor>
          </div>
        </div>
      </div>

      {/* view report */}
      {modalStatus === "reportView" && (
        <ReportView
          show={show}
          setShow={setShow}
          reportId={viewId}
          status="resellerCollector"
        />
      )}
    </>
  );
}
