import React from "react";
import "./dashboardCard.css";

const DotLoder = () => {
  return (
    <div class="host">
      <div class="loading loading-0"></div>
      <div class="loading loading-1"></div>
      <div class="loading loading-2"></div>
    </div>
  );
};

export default DotLoder;
