export const managerPermission = (permission, bpSettings, lang) => {
  const permissionEng = [
    {
      id: 1,
      value: "customerAdd",
      label: "Customer Add",
      isChecked: permission?.customerAdd || false,
      disabled: false,
    },
    {
      id: 2,
      value: "customerEdit",
      label: "Customer Edit",
      isChecked: permission?.customerEdit || false,
      disabled: false,
    },
    {
      id: 3,
      value: "customerActivate",
      label: "Customer Activate",
      isChecked: permission?.customerActivate || false,
      disabled: false,
    },
    {
      id: 4,
      value: "customerDeactivate",
      label: "Customer Deactivate",
      isChecked: permission?.customerDeactivate || false,
      disabled: false,
    },
    {
      id: 5,
      value: "customerDelete",
      label: "Customer Delete",
      isChecked: permission?.customerDelete || false,
      disabled: false,
    },
    {
      id: 6,
      value: "billEdit",
      label: "Update Bill",
      isChecked: permission?.billEdit || false,
      disabled: false,
    },
    {
      id: 7,
      value: "monthlyFeeEdit",
      label: "Update Monthly Fee",
      isChecked: permission?.monthlyFeeEdit || false,
      disabled: false,
    },
    {
      id: 8,
      value: "sendSMS",
      label: "SMS Board",
      isChecked: permission?.sendSMS || false,
      disabled: false,
    },
    {
      id: 9,
      value: "billPosting",
      label: "Bill Posting",
      isChecked: permission?.billPosting || false,
      disabled: false,
    },
    {
      id: 10,
      value: "collectorAdd",
      label: "Collector Add",
      isChecked: permission?.collectorAdd || false,
      disabled: false,
    },
    {
      id: 11,
      value: "collectorEdit",
      label: "Collector Update",
      isChecked: permission?.collectorEdit || false,
      disabled: false,
    },
    {
      id: 12,
      value: "viewCollectorReport",
      label: "View Collector Report",
      isChecked: permission?.viewCollectorReport || false,
      disabled: false,
    },
    {
      id: 13,
      value: "readMessageLog",
      label: "See Message Log",
      isChecked: permission?.readMessageLog || false,
      disabled: false,
    },
    {
      id: 14,
      value: "bulkAreaEdit",
      label: "Bulk Area Edit",
      isChecked: permission?.bulkAreaEdit || false,
      disabled: !bpSettings?.bulkAreaEdit,
    },
    {
      id: 15,
      value: "bulkStatusEdit",
      label: "Bulk Status Edit",
      isChecked: permission?.bulkStatusEdit || false,
      disabled: !bpSettings?.bulkStatusEdit,
    },
    {
      id: 16,
      value: "bulkBillingCycleEdit",
      label: "Bulk BillingCycle Edit",
      isChecked: permission?.bulkBillingCycleEdit || false,
      disabled: !bpSettings?.bulkBillingCycleEdit,
    },
    {
      id: 17,
      value: "bulkPromiseDateEdit",
      label: "Bulk Promise Date Edit",
      isChecked: permission?.bulkPromiseDateEdit || false,
      disabled: !bpSettings?.bulkPromiseDateEdit,
    },
    {
      id: 18,
      value: "bulkAutoDisableEdit",
      label: "Bulk Auto Disable Edit",
      isChecked: permission?.bulkAutoDisableEdit || false,
      disabled: !bpSettings?.bulkAutoDisableEdit,
    },
    {
      id: 19,
      value: "bulkPackageEdit",
      label: "Bulk Package Edit",
      isChecked: permission?.bulkPackageEdit || false,
      disabled: !bpSettings?.bulkPackageEdit,
    },
    {
      id: 20,
      value: "bulkTransferToReseller",
      label: "Bulk Customer Transfer To Reseller",
      isChecked: permission?.bulkTransferToReseller || false,
      disabled: !bpSettings?.bulkTransferToReseller,
    },
    {
      id: 21,
      value: "bulkCustomerDelete",
      label: "Bulk Customer Delete",
      isChecked: permission?.bulkCustomerDelete || false,
      disabled: !bpSettings?.bulkCustomerDelete,
    },
    //   id: 22,
    //   value: "updateCustomerBalance",
    //   label: "Update Customer Balance",
    //   isChecked: permission?.updateCustomerBalance || false,
    // disabled: false,
    // },
    {
      id: 23,
      value: "reportDelete",
      label: "Bill Report Delete",
      isChecked: permission?.reportDelete || false,
      disabled: !bpSettings?.reportDelete,
    },
    {
      id: 24,
      value: "staffSalary",
      label: "Staff Salary",
      isChecked: permission?.staffSalary || false,
      disabled: false,
    },
    {
      id: 25,
      value: "dashboardCollectionData",
      label: "Dashboard Collection",
      isChecked: permission?.dashboardCollectionData || false,
      disabled: false,
    },
    {
      id: 26,
      value: "bulkCustomerMikrotikUpdate",
      label: "Bulk Customer Mikrotik Update",
      isChecked: permission?.bulkCustomerMikrotikUpdate || false,
      disabled: !bpSettings?.bulkCustomerMikrotikUpdate,
    },
    {
      id: 27,
      value: "billDiscount",
      label: "Bill Discount",
      isChecked: permission?.billDiscount || false,
      disabled: false,
    },
    {
      id: 28,
      value: "bulkCustomerRecharge",
      label: "Bulk Customer Recharge",
      isChecked: permission?.bulkCustomerRecharge || false,
      disabled: !bpSettings?.bulkCustomerRecharge,
    },
    {
      id: 29,
      value: "instantRechargeBillPrint",
      label: "Instant Recharge Bill Print",
      isChecked: permission?.instantRechargeBillPrint || false,
      disabled: !bpSettings?.instantRechargeBillPrint,
    },
    {
      id: 30,
      value: "supportTicketCategory",
      label: "Support Ticket Category",
      isChecked: permission?.supportTicketCategory || false,
      disabled: false,
    },
    {
      id: 31,
      value: "bulkPaymentStatusEdit",
      label: "Bulk Payment Status Edit",
      isChecked: permission?.bulkPaymentStatusEdit || false,
      disabled: false,
    },
    {
      id: 32,
      value: "customerInvoice",
      label: "Customer Invoice",
      isChecked: permission?.customerInvoice || false,
      disabled: !bpSettings?.customerInvoice,
    },
    {
      id: 33,
      value: "updateCustomerBalance",
      label: "Update Customer Balance",
      isChecked: permission?.updateCustomerBalance || false,
      disabled: !bpSettings?.updateCustomerBalance,
    },
  ];

  const permissionBangla = [
    {
      id: 1,
      value: "customerAdd",
      label: "গ্রাহক সংযোগ",
      isChecked: permission?.customerAdd || false,
      disabled: false,
    },
    {
      id: 2,
      value: "customerEdit",
      label: "গ্রাহক আপডেট",
      isChecked: permission?.customerEdit || false,
      disabled: false,
    },
    {
      id: 3,
      value: "customerActivate",
      label: "গ্রাহক এক্টিভেট",
      isChecked: permission?.customerActivate || false,
      disabled: false,
    },
    {
      id: 4,
      value: "customerDeactivate",
      label: "গ্রাহক বন্ধ",
      isChecked: permission?.customerDeactivate || false,
      disabled: false,
    },
    {
      id: 5,
      value: "customerDelete",
      label: "গ্রাহক ডিলিট",
      isChecked: permission?.customerDelete || false,
      disabled: false,
    },
    {
      id: 6,
      value: "billEdit",
      label: "বিল আপডেট",
      isChecked: permission?.billEdit || false,
      disabled: false,
    },
    {
      id: 7,
      value: "monthlyFeeEdit",
      label: "মাসিক ফি আপডেট",
      isChecked: permission?.monthlyFeeEdit || false,
      disabled: false,
    },
    {
      id: 8,
      value: "sendSMS",
      label: "ম্যাসেজ বোর্ড",
      isChecked: permission?.sendSMS || false,
      disabled: false,
    },
    {
      id: 9,
      value: "billPosting",
      label: "বিল পোস্টিং",
      isChecked: permission?.billPosting || false,
      disabled: false,
    },
    {
      id: 10,
      value: "collectorAdd",
      label: "কালেক্টর অ্যাড",
      isChecked: permission?.collectorAdd || false,
      disabled: false,
    },
    {
      id: 11,
      value: "collectorEdit",
      label: "কালেক্টর আপডেট",
      isChecked: permission?.collectorEdit || false,
      disabled: false,
    },
    {
      id: 12,
      value: "viewCollectorReport",
      label: "কালেক্টর রিপোর্ট দেখবে",
      isChecked: permission?.viewCollectorReport || false,
      disabled: false,
    },
    {
      id: 13,
      value: "readMessageLog",
      label: "চেক মেসেস লগ",
      isChecked: permission?.readMessageLog || false,
      disabled: false,
    },
    {
      id: 14,
      value: "bulkAreaEdit",
      label: "বাল্ক এরিয়া এডিট",
      isChecked: permission?.bulkAreaEdit || false,
      disabled: !bpSettings?.bulkAreaEdit,
    },
    {
      id: 15,
      value: "bulkStatusEdit",
      label: "বাল্ক স্ট্যাটাস এডিট",
      isChecked: permission?.bulkStatusEdit || false,
      disabled: !bpSettings?.bulkStatusEdit,
    },
    {
      id: 16,
      value: "bulkBillingCycleEdit",
      label: "বাল্ক বিলিং সাইকেল এডিট",
      isChecked: permission?.bulkBillingCycleEdit || false,
      disabled: !bpSettings?.bulkBillingCycleEdit,
    },
    {
      id: 17,
      value: "bulkPromiseDateEdit",
      label: "বাল্ক প্রমিজ ডেট এডিট",
      isChecked: permission?.bulkPromiseDateEdit || false,
      disabled: !bpSettings?.bulkPromiseDateEdit,
    },
    {
      id: 18,
      value: "bulkAutoDisableEdit",
      label: "বাল্ক আটো ডিজেবল এডিট",
      isChecked: permission?.bulkAutoDisableEdit || false,
      disabled: !bpSettings?.bulkAutoDisableEdit,
    },
    {
      id: 19,
      value: "bulkPackageEdit",
      label: "বাল্ক প্যকেজ এডিট",
      isChecked: permission?.bulkPackageEdit || false,
      disabled: !bpSettings?.bulkPackageEdit,
    },
    {
      id: 20,
      value: "bulkTransferToReseller",
      label: "বাল্ক রিসেলার গ্রাহক ট্রান্সফার",
      isChecked: permission?.bulkTransferToReseller || false,
      disabled: !bpSettings?.bulkTransferToReseller,
    },
    {
      id: 21,
      value: "bulkCustomerDelete",
      label: "বাল্ক গ্রাহক ডিলিট",
      isChecked: permission?.bulkCustomerDelete || false,
      disabled: !bpSettings?.bulkCustomerDelete,
    },
    // {
    //   id: 22,
    //   value: "updateCustomerBalance",
    //   label: "গ্রাহক ব্যালেন্স এডিট",
    //   isChecked: permission?.updateCustomerBalance || false,
    // disabled: false,
    // },
    {
      id: 23,
      value: "reportDelete",
      label: "বিল রিপোর্ট ডিলিট",
      isChecked: permission?.reportDelete || false,
      disabled: !bpSettings?.reportDelete,
    },
    {
      id: 24,
      value: "staffSalary",
      label: "স্টাফ স্যলারি",
      isChecked: permission?.staffSalary || false,
      disabled: false,
    },
    {
      id: 25,
      value: "dashboardCollectionData",
      label: "ড্যাশবোর্ড কালেকশন",
      isChecked: permission?.dashboardCollectionData || false,
      disabled: false,
    },
    {
      id: 26,
      value: "bulkCustomerMikrotikUpdate",
      label: "বাল্ক গ্রাহকের মাইক্রোটিক পরিবর্তন",
      isChecked: permission?.bulkCustomerMikrotikUpdate || false,
      disabled: !bpSettings?.bulkCustomerMikrotikUpdate,
    },
    {
      id: 27,
      value: "billDiscount",
      label: "বিল দিসকাউন্ট",
      isChecked: permission?.billDiscount || false,
      disabled: false,
    },
    {
      id: 28,
      value: "bulkCustomerRecharge",
      label: "বাল্ক গ্রাহক রিচার্জ",
      isChecked: permission?.bulkCustomerRecharge || false,
      disabled: !bpSettings?.bulkCustomerRecharge,
    },
    {
      id: 29,
      value: "instantRechargeBillPrint",
      label: "ইনস্ট্যান্ট রিচার্জ বিল প্রিন্ট",
      isChecked: permission?.instantRechargeBillPrint || false,
      disabled: !bpSettings?.instantRechargeBillPrint,
    },
    {
      id: 30,
      value: "supportTicketCategory",
      label: "সাপোর্ট টিকেট ক্যাটাগরি",
      isChecked: permission?.supportTicketCategory || false,
      disabled: false,
    },
    {
      id: 31,
      value: "bulkPaymentStatusEdit",
      label: "বাল্ক পেমেন্ট স্ট্যাটাস এডিট",
      isChecked: permission?.bulkPaymentStatusEdit || false,
      disabled: false,
    },
    {
      id: 32,
      value: "customerInvoice",
      label: "কাস্টমার ইনভয়েস",
      isChecked: permission?.customerInvoice || false,
      disabled: !bpSettings?.customerInvoice,
    },
    {
      id: 33,
      value: "updateCustomerBalance",
      label: "গ্রাহক ব্যালেন্স আপডেট",
      isChecked: permission?.updateCustomerBalance || false,
      disabled: !bpSettings?.updateCustomerBalance,
    },
  ];

  if (lang === "bn") return permissionBangla;
  return permissionEng;
};
