import React from "react";

export default function Loader() {
  return (
    <>
      <img
        className="loader_style"
        src={"https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"}
        alt=""
      />
    </>
  );
}
