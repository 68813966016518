export const tutorialLink = [
  //   {
  //     id: 1,
  //     title: "NetFee Software Registration Process",
  //     link: "https://www.youtube.com/embed/t-5g8C--fpc?si=XFLOzp_Fv_hTScHM",
  //   },
  {
    id: 2,
    title: "NetFee Support",
    link: "https://www.youtube.com/embed/rX33-TqCIwU?si=UcFv5_ySnFFjRhBq",
  },
  {
    id: 3,
    title: "NetFee Admin Panel",
    link: "https://www.youtube.com/embed/7DVn0mQGGH0?si=nV1SuLt3SlNU0sIT",
  },
];
