import styled from "styled-components";

export const Background = styled.div`
  background-color: ${(props) => props.theme.body};
`;

export const FontColor = styled.div`
  color: ${(props) => props.theme.fontColor};
`;

export const TitleColor = styled.div`
  color: ${(props) => props.theme.titleColor};
`;

export const FourGround = styled.div`
  background-color: ${(props) => props.theme.furGround};
`;
