import { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

// internal imports
import "../../collector/collector.css";
import { FtextField } from "../../../components/common/FtextField";
import Loader from "../../../components/common/Loader";

import { addSubArea } from "../../../features/apiCalls";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";

export default function SubAreaPost({ postShow, setPostShow, name, id }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.persistedReducer.auth.currentUser);
  const [isLoading, setIsLoading] = useState(false);

  //validator
  const linemanValidator = Yup.object({
    name: Yup.string().required(t("enterName")),
  });

  //modal show handler
  const handleClose = () => {
    setPostShow(false);
  };

  const subAreaHandler = async (data) => {
    if (auth.ispOwner) {
      const sendingData = {
        name: data.name,
        area: id,
        ispOwner: auth.ispOwner.id,
      };
      addSubArea(dispatch, sendingData, setIsLoading, setPostShow);
    }
  };

  return (
    <Modal
      show={postShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader closeButton>
        <ModalTitle>
          {name || ""} - {t("addSubArea")}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            name: "",
          }}
          validationSchema={linemanValidator}
          onSubmit={(values) => {
            subAreaHandler(values);
          }}
        >
          {() => (
            <Form id="subAreaPost">
              <FtextField type="text" label={t("nameSubArea")} name="name" />
            </Form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClose}
          disabled={isLoading}
        >
          {t("cancel")}
        </button>
        <button
          type="submit"
          form="subAreaPost"
          className="btn btn-success customBtn"
          disabled={isLoading}
        >
          {isLoading ? <Loader /> : t("save")}
        </button>
      </ModalFooter>
    </Modal>
  );
}
