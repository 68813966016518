import { People, CashStack, CartCheck, ChatDots } from "react-bootstrap-icons";

export const cardData = [
  {
    icon: <People />,
    title: "মোট গ্রাহক",
    balance: "5289",
    classnam: "card1",
  },
  {
    icon: <CashStack />,
    title: "ব্যালান্স",
    balance: "$ 2300",
    classnam: "card2",
  },
  {
    icon: <CartCheck />,
    title: "নিউ সেলস",
    balance: "231",
    classnam: "card3",
  },
  {
    icon: <ChatDots />,
    title: "পেন্ডিং",
    balance: "23",
    classnam: "card4",
  },
];

export const monthsName = [
  "জানুয়ারি",
  "ফেব্রুয়ারি",
  "মার্চ",
  "এপ্রিল",
  "মে",
  "জুন",
  "জুলাই",
  "আগস্ট",
  "সেপ্টেম্বর",
  "অক্টোবর",
  "নভেম্বর",
  "ডিসেম্বর",
];
