import { useRef, useState } from "react";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ReactToPrint from "react-to-print";

//internal imports
import { FtextField } from "../../../components/common/FtextField";
import "../../Customer/customer.css";
import { createCustomerInvoice } from "../../../features/apiCalls";
import Loader from "../../../components/common/Loader";
import RechargePrintInvoice from "./bulkOpration/RechargePrintInvoice";
import ComponentCustomModal from "../../../components/common/customModal/ComponentCustomModal";

const CreateInvoice = ({ show, setShow, single, customerData }) => {
  const { t } = useTranslation();

  const options = [
    { value: "January", label: t("january") },
    { value: "February", label: t("february") },
    { value: "March", label: t("march") },
    { value: "April", label: t("april") },
    { value: "May", label: t("may") },
    { value: "June", label: t("june") },
    { value: "July", label: t("July") },
    { value: "August", label: t("august") },
    { value: "September", label: t("september") },
    { value: "October", label: t("october") },
    { value: "November", label: t("november") },
    { value: "December", label: t("december") },
  ];

  // get all customer
  const customer = useSelector((state) => state?.customer?.customer);

  // find editable data
  const data = customer.find((item) => item.id === single);

  // get all role
  const role = useSelector((state) => state.persistedReducer.auth.role);

  // get user permission
  const permission = useSelector(
    (state) => state.persistedReducer.auth.userData.permissions
  );

  // get bpSettings
  const bpSettings = useSelector(
    (state) => state.persistedReducer.auth?.ispOwnerData?.bpSettings
  );
  // get ispOwner info
  const ispOwner = useSelector(
    (state) => state.persistedReducer.auth?.ispOwnerId
  );

  // get userData
  const userData = useSelector((state) => state.persistedReducer.auth.userData);

  // get currentUser
  const currentUser = useSelector(
    (state) => state.persistedReducer.auth?.currentUser
  );

  // get currentUserId
  const currentUserId = useSelector(
    (state) => state.persistedReducer.auth?.userData?.id
  );

  const [isLoading, setLoading] = useState(false);

  //billing date
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [medium, setMedium] = useState("cash");
  const [noteCheck, setNoteCheck] = useState(false);
  const [note, setNote] = useState("");
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [billAmount, setBillAmount] = useState();
  const [balanceDue, setBalanceDue] = useState();
  const [billType, setBillType] = useState("bill");
  const [amount, setAmount] = useState(null);
  const totalAmount = Number(billAmount) + Number(balanceDue);
  const maxDiscount = totalAmount;

  //response data after API call after payment
  const [responseData, setResponseData] = useState({});
  const [test, setTest] = useState(false);

  const rechargePrint = useRef();

  //print button is clicked after successful response
  useEffect(() => {
    if (test) {
      if (
        (role === "ispOwner" && bpSettings?.instantRechargeBillPrint) ||
        ((role === "manager" || role === "collector") &&
          permission?.instantRechargeBillPrint &&
          bpSettings?.instantRechargeBillPrint)
      ) {
        document.getElementById("printButton").click();
        setTest(!test);
      }
    }
  }, [test]);

  //Validation
  const BillValidatoin = Yup.object({
    amount: Yup.number()
      .min(Math.floor(data?.monthlyFee / 3), t("billNotAcceptable"))
      .integer(t("decimalNumberNotAcceptable")),
    due: Yup.number()
      .min(0, t("dueNotAcceptable"))
      .max(
        data?.balance < 0 ? Math.abs(data?.balance) : 0,
        t("dueNotAcceptable")
      )
      .integer(t("decimalNumberNotAcceptable")),
    discount: Yup.number()
      .min(0, t("discountNotAcceptable"))
      .max(maxDiscount, t("discountNotAcceptable"))
      .integer(t("decimalNumberNotAcceptable")),
  });

  //form resetFunction
  const resetForm = () => {
    setStartDate(false);
    setEndDate(false);
    setNote("");
    setNoteCheck(false);
    setSelectedMonth(null);
    setBillAmount(
      data?.balance > 0 && data?.balance <= data?.monthlyFee
        ? data?.monthlyFee - data?.balance
        : data?.balance > data?.monthlyFee
        ? 0
        : data?.monthlyFee
    );
  };

  useEffect(() => {
    setBalanceDue(data?.balance < 0 ? Math.abs(data?.balance) : 0);
    setBillAmount(
      data?.balance > 0 && data?.balance <= data?.monthlyFee
        ? data?.monthlyFee - data?.balance
        : data?.balance > data?.monthlyFee
        ? 0
        : data?.monthlyFee
    );

    let temp = [];

    const dataMonth = new Date(data?.billingCycle).getMonth();

    if (data?.balance === 0 && data?.paymentStatus === "unpaid") {
      setSelectedMonth([options[dataMonth]]);
    } else if (data?.balance === 0 && data?.paymentStatus === "paid") {
      temp.push(options[dataMonth + 1]);
      if (dataMonth + 1 > 11) setSelectedMonth([]);
      else setSelectedMonth(temp);
    } else if (data?.balance > 0 && data?.paymentStatus === "paid") {
      const modVal = Math.floor(data?.balance / data?.monthlyFee);
      temp.push(options[dataMonth + modVal + 1]);

      if (dataMonth + modVal + 1 > 11) setSelectedMonth([]);
      else setSelectedMonth(temp);
    } else if (data?.balance < 0 && data?.paymentStatus === "unpaid") {
      const modVal = Math.floor(Math.abs(data?.balance / data?.monthlyFee));

      let diff = dataMonth - modVal;
      if (diff < 0) {
        diff = 0;
      }

      for (let i = diff; i <= dataMonth; i++) {
        temp.push(options[i]);
      }
      setSelectedMonth(temp);
    }
  }, [data]);

  const handleFormValue = (event) => {
    if (event.target.name === "amount") {
      setBillAmount(event.target.value);
    }
    if (event.target.name === "due") {
      setBalanceDue(event.target.value);
    }
  };

  // bill amount
  const customerBillHandler = (formValue) => {
    if (balanceDue > (data?.balance < 0 ? Math.abs(data?.balance) : 0)) {
      setLoading(false);
      return alert(t("dueNotAcceptable"));
    }

    if (balanceDue < 0) {
      setLoading(false);
      return alert(t("dueNotAcceptable"));
    }
    if (maxDiscount < formValue.discount) {
      setLoading(false);
      return alert(t("discountNotAcceptable"));
    }

    const sendingData = {
      amount: formValue.amount + formValue.due,
      discount: formValue.discount,
      name: userData.name,
      collectedBy: currentUser?.user.role,
      billType: billType,
      customer: data?.id,
      ispOwner: ispOwner,
      user: currentUser?.user.id,
      collectorId: currentUserId, //when collector is logged in
      medium,
      package: data?.pppoe.profile,
      invoiceType: "customerInvoice",
    };

    if (note) sendingData.note = note;

    if (startDate && endDate) {
      sendingData.start = startDate.toISOString();
      sendingData.end = endDate.toISOString();
    }
    if (selectedMonth?.length > 0) {
      const monthValues = selectedMonth.map((item) => {
        return item.value;
      });
      sendingData.month = monthValues.join(",");
    }

    createCustomerInvoice(sendingData, setLoading, resetForm, setShow);

    setAmount(data.amount);
  };

  return (
    <>
      <ComponentCustomModal
        show={show}
        setShow={setShow}
        centered={false}
        size={"md"}
        header={t("recharge")}
      >
        <Formik
          initialValues={{
            amount:
              data?.balance > 0 && data?.balance <= data?.monthlyFee
                ? data?.monthlyFee - data?.balance
                : data?.balance > data?.monthlyFee
                ? 0
                : data?.monthlyFee,
            due: data?.balance < 0 ? Math.abs(data?.balance) : 0,
            discount: 0,
          }}
          validationSchema={BillValidatoin}
          onSubmit={(values) => {
            customerBillHandler(values);
          }}
          enableReinitialize
        >
          {() => (
            <Form onChange={handleFormValue}>
              <table
                className="table table-bordered"
                style={{ lineHeight: "12px" }}
              >
                <tbody>
                  <tr>
                    <td>{t("id")}</td>
                    <td>
                      <b>{data?.customerId}</b>
                    </td>
                    <td>{t("pppoe")}</td>
                    <td>
                      <b>{data?.pppoe.name}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("name")}</td>
                    <td>
                      <b>{data?.name}</b>
                    </td>
                    <td>{t("mobile")}</td>
                    <td className="text-primary">
                      <b>{data?.mobile}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("monthly")}</td>
                    <td className="text-success">
                      <b>{data?.monthlyFee}</b>
                    </td>
                    <td>{t("balance")}</td>
                    <td className="text-info">
                      <b>{data?.balance}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h6>
                <span className="text-success">{t("totalBillAmount")}</span>
                <span className="text-danger">{totalAmount} </span>
              </h6>

              <div className="displayGrid">
                <div className="displayGrid2">
                  <FtextField
                    type="number"
                    name="amount"
                    label={t("amount")}
                    value={billAmount}
                  />
                  <FtextField type="number" name="due" label={t("due")} />
                </div>

                <div className="displayGrid2">
                  <div>
                    <label className="form-control-label changeLabelFontColor">
                      {t("billType")}
                    </label>

                    <select
                      className="form-select mt-0 mw-100"
                      onChange={(e) => setBillType(e.target.value)}
                    >
                      <option value="bill"> {t("bill")} </option>
                      {(permission?.connectionFee || role !== "collector") && (
                        <option value="connectionFee">
                          {t("connectionFee")}
                        </option>
                      )}
                    </select>
                  </div>

                  <div>
                    <label className="form-control-label changeLabelFontColor">
                      {t("medium")}
                    </label>

                    <select
                      as="select"
                      className="form-select mt-0 mw-100"
                      aria-label="Default select example"
                      onChange={(e) => setMedium(e.target.value)}
                    >
                      <option value="cash" selected>
                        {t("handCash")}
                      </option>
                      <option value="bKash"> {t("bKash")} </option>
                      <option value="rocket"> {t("rocket")} </option>
                      <option value="nagad"> {t("nagad")} </option>
                      <option value="others"> {t("others")} </option>
                    </select>
                  </div>
                </div>

                <div className="month">
                  <label className="form-check-label changeLabelFontColor">
                    {t("selectMonth")}
                  </label>

                  <Select
                    value={selectedMonth}
                    onChange={(data) => setSelectedMonth(data)}
                    options={options}
                    isMulti={true}
                    placeholder={t("selectMonth")}
                    isSearchable
                  />
                </div>

                <div className="displayGrid2">
                  {(role === "ispOwner" || permission.billDiscount) && (
                    <FtextField
                      type="number"
                      name="discount"
                      label={t("discount")}
                    />
                  )}

                  <div className="d-flex align-self-end">
                    <input
                      type="checkbox"
                      className="form-check-input me-1"
                      id="invoiceNote"
                      checked={noteCheck}
                      onChange={(e) => setNoteCheck(e.target.checked)}
                    />

                    <label
                      className="form-check-label changeLabelFontColor"
                      htmlFor="invoiceNote"
                    >
                      {t("noteAndDate")}
                    </label>
                  </div>
                </div>

                {noteCheck && (
                  <>
                    <div className="displayGrid2">
                      <div>
                        <label className="form-control-label changeLabelFontColor">
                          {t("startDate")}
                        </label>

                        <DatePicker
                          className="form-control mw-100"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText={t("selectDate")}
                        />
                      </div>

                      <div>
                        <label className="form-control-label changeLabelFontColor">
                          {t("endDate")}
                        </label>

                        <DatePicker
                          className="form-control mw-100"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText={t("selectDate")}
                        />
                      </div>
                    </div>

                    <div class="form-floating">
                      <textarea
                        cols={200}
                        className="form-control shadow-none"
                        placeholder={t("writeNote")}
                        id="noteField"
                        onChange={(e) => setNote(e.target.value)}
                      ></textarea>
                      <label for="noteField"> {t("addNote")} </label>
                    </div>
                  </>
                )}
              </div>

              {/* Invoice Printer Page Component with button and they are hidden*/}
              <>
                {((role === "ispOwner" &&
                  bpSettings?.instantRechargeBillPrint) ||
                  ((role === "manager" || role === "collector") &&
                    permission?.instantRechargeBillPrint &&
                    bpSettings?.instantRechargeBillPrint)) && (
                  <div className="d-none">
                    <RechargePrintInvoice
                      ref={rechargePrint}
                      customerData={customerData}
                      billingData={responseData}
                      ispOwnerData={userData}
                    />
                  </div>
                )}

                <div className="d-none">
                  <ReactToPrint
                    documentTitle={t("billInvoice")}
                    trigger={() => (
                      <div
                        title={t("printInvoiceBill")}
                        style={{ cursor: "pointer" }}
                      >
                        <button type="button" id="printButton">
                          Print
                        </button>
                      </div>
                    )}
                    content={() => rechargePrint.current}
                  />
                </div>
              </>

              <div className="float-end mt-4">
                <button type="submit" className="btn btn-success">
                  {isLoading ? <Loader /> : t("submit")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ComponentCustomModal>
    </>
  );
};

export default CreateInvoice;
