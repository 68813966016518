import { createSlice } from "@reduxjs/toolkit";

const resellerCustomerSlice = createSlice({
  name: "resellerCustomer",
  initialState: {
    dueCustomer: [],
    newCustomer: [],
    activeCustomer: [],
    inactiveCustomer: [],
    resellerCustomer: [],
    allResellerCustomer: [],
    resellerCustomerBillReport: [],
  },
  reducers: {
    getAllResellerCustomerSuccess: (state, actions) => {
      state.allResellerCustomer = actions.payload;
    },
    getResellerCustomerSuccess: (state, actions) => {
      state.resellerCustomer = actions.payload;
    },
    editResellerCustomerSuccess: (state, action) => {
      state.resellerCustomer[
        state.resellerCustomer.findIndex(
          (item) => item.id === action.payload.id
        )
      ] = action.payload;
    },
    editAllResellerCustomerSuccess: (state, action) => {
      state.allResellerCustomer[
        state.allResellerCustomer.findIndex(
          (item) => item.id === action.payload.id
        )
      ] = action.payload;
    },

    bulkUpdate: (state, { payload }) => {
      //get the user type
      const userType = payload[0].userType;
      let customers;
      //get the value from state based on userType
      if (userType === "pppoe") {
        customers = [...state.resellerCustomer];
      } else if (userType === "simple-queue" || userType === "firewall-queue") {
        customers = [...state.resellerCustomer];
      }
      for (let i = 0; i < customers.length; i++) {
        const element = customers[i];
        for (let j = 0; j < payload.length; j++) {
          if (element.id === payload[j].id) {
            customers[i] = payload[j];
          }
        }
      }
      //update the state based on userType with modified state
      if (userType === "pppoe") {
        state.resellerCustomer = [...customers];
      } else if (userType === "simple-queue" || userType === "firewall-queue") {
        state.resellerCustomer = [...customers];
      }
    },

    deleteReCustomer: (state, actions) => {
      state.resellerCustomer = state.resellerCustomer.filter(
        (item) => item.id !== actions.payload
      );
      state.allResellerCustomer = state.allResellerCustomer.filter(
        (item) => item.id !== actions.payload
      );
    },
    bulkCustomerReturn: (state, { payload }) => {
      let customers = [];
      if (payload.isAllCustomer) {
        customers = [...state.allResellerCustomer];
      } else {
        customers = [...state.resellerCustomer];
      }
      const updatedCustomer = [];
      //loop through existing customer
      for (let i = 0; i < customers.length; i++) {
        const element = customers[i];
        const found = payload.data.find((item) => item.id === element.id);
        if (!found) updatedCustomer.push(element);
      }
      if (payload.isAllCustomer) {
        state.allResellerCustomer = updatedCustomer;
      } else {
        state.resellerCustomer = updatedCustomer;
      }
    },

    bulkResellerDelete: (state, { payload }) => {
      //get the user type
      const userType = payload[0].userType;
      let customers;
      //get the value from state based on userType
      if (userType === "pppoe") {
        customers = [...state.resellerCustomer];
      } else if (userType === "simple-queue" || userType === "firewall-queue") {
        customers = [...state.resellerCustomer];
      }
      const updatedCustomer = [];
      //loop through existing customer
      for (let i = 0; i < customers.length; i++) {
        const element = customers[i];
        const found = payload.find((item) => item.id === element.id);
        if (!found) updatedCustomer.push(element);
      }
      //update the state based on userType with modified state
      if (userType === "pppoe") {
        state.resellerCustomer = [...updatedCustomer];
      } else if (userType === "simple-queue" || userType === "firewall-queue") {
        state.resellerCustomer = [...updatedCustomer];
      }
    },

    // reseller single customer bill report action
    getResellerCustomerBillReportSuccess: (state, actions) => {
      state.resellerCustomerBillReport = actions.payload;
    },

    // dashboard click modal action
    getDashboardActiveCustomer: (state, actions) => {
      state.activeCustomer = actions.payload;
    },

    // reseller other customer action
    getNewCustomerSuccess: (state, actions) => {
      state.newCustomer = actions.payload;
    },

    getInactiveCustomerSuccess: (state, actions) => {
      state.inactiveCustomer = actions.payload;
    },

    getDueCustomerSuccess: (state, actions) => {
      state.dueCustomer = actions.payload;
    },
  },
});

export const {
  getDueCustomerSuccess,
  getNewCustomerSuccess,
  getDashboardActiveCustomer,
  getInactiveCustomerSuccess,
  getResellerCustomerSuccess,
  editResellerCustomerSuccess,
  deleteReCustomer,
  getAllResellerCustomerSuccess,
  editAllResellerCustomerSuccess,
  bulkCustomerReturn,
  bulkUpdate,
  bulkResellerDelete,
  getResellerCustomerBillReportSuccess,
} = resellerCustomerSlice.actions;
export default resellerCustomerSlice.reducer;
