//warning: don't change the object order and value name

export const printOptionData = {
  customer: [
    { id: 1001, value: "id", label: "id", checked: true },
    { id: 1002, value: "name", label: "name", checked: true },
    // {id:1003} print option according to customer page
    {
      id: 1004,
      value: "mobile",
      label: "mobile",
      checked: true,
    },
    {
      id: 1005,
      value: "address",
      label: "address",
      checked: false,
    },
    {
      id: 1006,
      value: "package",
      label: "package",
      checked: true,
    },
    {
      id: 1007,
      value: "bill",
      label: "bill",
      checked: true,
    },
    {
      id: 1008,
      value: "balance",
      label: "balance",
      checked: true,
    },
    {
      id: 1009,
      value: "bill",
      label: "billDate",
      checked: true,
    },
    {
      id: 1010,
      value: "promise",
      label: "promiseDate",
      checked: false,
    },
    {
      id: 1024,
      value: "paymentStatus",
      label: "paymentStatus",
      checked: true,
    },
    {
      id: 1011,
      value: "status",
      label: "status",
      checked: true,
    },
    {
      id: 1012,
      value: "creatDate",
      label: "createDate",
      checked: false,
    },
    {
      id: 1013,
      value: "connDate",
      label: "connectionDate",
      checked: false,
    },
  ],

  report: [
    {
      id: 1014,
      value: "name",
      label: "name",
      checked: true,
    },
    {
      id: 1015,
      value: "mobile",
      label: "mobile",
      checked: true,
    },
    {
      id: 1016,
      value: "package",
      label: "package",
      checked: true,
    },
    {
      id: 1017,
      value: "monthlyFee",
      label: "monthlyFee",
      checked: true,
    },
    {
      id: 1018,
      value: "address",
      label: "address",
      checked: true,
    },
    {
      id: 1019,
      value: "amount",
      label: "amount",
      checked: true,
    },
    {
      id: 1020,
      value: "billType",
      label: "billType",
      checked: true,
    },
    {
      id: 1021,
      value: "medium",
      label: "medium",
      checked: true,
    },
    {
      id: 1022,
      value: "discount",
      label: "discount",
      checked: true,
    },
    {
      id: 1023,
      value: "due",
      label: "due",
      checked: true,
    },
  ],
};
// bill report page create option ids
// { id: 5016 },
// { id: 5017 },
