import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { badge } from "../../components/common/Utils";
import Table from "../../components/table/Table";
import {
  getFixedNumberMessageLog,
  getMaskingMessageLog,
  getMessageLog,
} from "../../features/messageLogApi";
import { Accordion } from "react-bootstrap";

const FixedNumber = ({
  fixedNumberLoading,
  setFixedNumberLoading,
  activeKeys,
}) => {
  const { t } = useTranslation();
  // import dispatch
  const dispatch = useDispatch();

  // get reseller id
  const resellerId = useSelector(
    (state) => state.persistedReducer.auth?.userData?.id
  );

  // get all fixedNumber from redux
  const fixedNumber = useSelector((state) => state?.messageLog?.fixedNumber);

  // main fixedNumber state
  const [fixedNumberMessage, setFixedNUmberMessage] = useState([]);

  // get Current date
  const today = new Date();

  // get first date of month
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  // start date state
  const [startDate, setStartDate] = useState(firstDay);

  // end date state
  const [endDate, setEndDate] = useState(today);

  // type state
  const [type, setType] = useState("");

  // status state
  const [status, setStatus] = useState("");

  // filter function
  const onClickFilter = () => {
    let filterData = [...fixedNumber];

    // type filter
    if (type) {
      filterData = filterData.filter((item) => item.type === type);
    }

    // status filter
    if (status) {
      filterData = filterData.filter((item) => item.status === status);
    }

    // date filter
    filterData = filterData.filter(
      (value) =>
        new Date(moment(value.createdAt).format("YYYY-MM-DD")).getTime() >=
          new Date(moment(startDate).format("YYYY-MM-DD")).getTime() &&
        new Date(moment(value.createdAt).format("YYYY-MM-DD")).getTime() <=
          new Date(moment(endDate).format("YYYY-MM-DD")).getTime()
    );

    setFixedNUmberMessage(filterData);
  };

  // get customer api call
  useEffect(() => {
    if (fixedNumberMessage.length === 0)
      getFixedNumberMessageLog(dispatch, setFixedNumberLoading, resellerId);
  }, []);

  // set main fixedNumber at state
  useEffect(() => {
    setFixedNUmberMessage(fixedNumber);
  }, [fixedNumber]);

  // table column
  const columns = React.useMemo(
    () => [
      {
        width: "5%",
        Header: "#",
        id: "row",
        accessor: (row) => Number(row.id + 1),
        Cell: ({ row }) => <strong>{Number(row.id) + 1}</strong>,
      },
      {
        width: "13%",
        Header: t("mobile"),
        accessor: "mobile",
      },
      {
        width: "9%",
        Header: t("status"),
        accessor: "status",
        Cell: ({ cell: { value } }) => {
          return badge(value);
        },
      },
      {
        width: "8%",
        Header: t("type"),
        accessor: "type",
        Cell: ({ cell: { value } }) => {
          return <div className="text-center">{badge(value)}</div>;
        },
      },
      {
        width: "8%",
        Header: t("count"),
        accessor: "count",
        Cell: ({ cell: { value } }) => {
          return <div className="text-center">{value}</div>;
        },
      },
      {
        width: "12%",
        Header: t("createdAt"),
        accessor: "createdAt",
        Cell: ({ cell: { value } }) => {
          return moment(value).format("YYYY/MM/DD hh:mm A");
        },
      },
      {
        width: "45%",
        Header: t("message"),
        accessor: "message",
      },
    ],
    [t]
  );
  return (
    <>
      <Accordion alwaysOpen activeKey={activeKeys}>
        <Accordion.Item eventKey="filter" className="accordionBorder">
          <Accordion.Body className="accordionPadding pt-2">
            <div className="selectFilteringg">
              <div className="typeFilter">
                <select
                  className="form-select w-200 mt-0"
                  onChange={(event) => setType(event.target.value)}
                >
                  <option value="" selected>
                    {t("type")}
                  </option>

                  <option value="bill">{t("bill")}</option>
                  <option value="bulk">{t("bulk")}</option>
                  <option value="other">{t("other")}</option>
                </select>
              </div>
              <div className="mx-2">
                <select
                  className="form-select w-200 mt-0"
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <option value="" selected>
                    {t("selectStatus")}
                  </option>

                  <option value="sent">{t("send")}</option>
                  <option value="Pending">{t("selectPending")}</option>
                </select>
              </div>
              <div>
                <ReactDatePicker
                  className="form-control w-200 mt-0"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MMM dd yyyy"
                  placeholderText={t("selectBillDate")}
                />
              </div>
              <div className="mx-2">
                <ReactDatePicker
                  className="form-control w-200 mt-0"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="MMM dd yyyy"
                  placeholderText={t("selectBillDate")}
                />
              </div>
              <div className="">
                <button
                  className="btn btn-outline-primary w-140 mt-0"
                  type="button"
                  onClick={onClickFilter}
                >
                  {t("filter")}
                </button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className="collectorWrapper pb-2">
        <div className="addCollector">
          <Table
            isLoading={fixedNumberLoading}
            columns={columns}
            data={fixedNumber}
          ></Table>
        </div>
      </div>
    </>
  );
};

export default FixedNumber;
