import apiLink from "../api/apiLink";
import { toast } from "react-toastify";

// import { hideModal } from "./actions/managerHandle";
import { FetchAreaSuccess } from "./areaSlice";
import {
  addCollectorSuccess,
  deleteCollectorSuccess,
  editCollectorSuccess,
  getCollectorBills,
  getCollectorSuccess,
} from "./collectorSlice";
import {
  addCustomerSuccess,
  addStaticCustomerSuccess,
  deleteCustomerSuccess,
  deleteStaticCustomerSuccess,
  editCustomerSuccess,
  editStaticCustomerSuccess,
  getCustomerConnectionFeeDue,
  getCustomerSuccess,
  getStaticCustomerActiveSuccess,
  getStaticCustomerSuccess,
  updateBalance,
  updateBalanceStaticCustomer,
} from "./customerSlice";

import { updateProfile } from "./authSlice";
import {
  getAllBillsSuccess,
  getDepositSuccess,
  getmyDepositSucces,
  getTotalBalanceSuccess,
  updateDepositSuccess,
  addDepositSucces,
  postWithdrawBalance,
  getWithdrawBalance,
} from "./paymentSlice";
import { getChartSuccess } from "./chartsSlice";
import { getMikrotikSuccess, getpppoePackageSuccess } from "./mikrotikSlice";
import { getAllRechargeHistory } from "./rechargeSlice";
import { getAllMikrotikPakages, getResellerUsersData } from "./resellerSlice";
import {
  addResellerSupport,
  getResellerSupport,
  updateResellerSupport,
} from "./resellerSupportSlice";
import { getResellerProfile } from "./resellerProfileSlice";
import {
  getResellerChangePackageRequest,
  updateResellerChangePackageRequest,
} from "./netFeeSupportSlice";

const netFeeLang = localStorage.getItem("netFee:lang");
const langMessage = (color, bangla, english) => {
  // Notification for english language
  if (netFeeLang === "bn") {
    if (color === "success") {
      return toast.success(bangla);
    } else {
      return toast.error(bangla);
    }
  }

  // Notification for Bangla language
  if (netFeeLang === "en") {
    if (color === "success") {
      return toast.success(english);
    } else {
      return toast.error(english);
    }
  }
};

export const getCharts = async (dispatch, resellerId, Year, Month, User) => {
  try {
    let link = `/dashboard/${resellerId}?year=${Year}&month=${Month}`;
    if (User)
      link = `/dashboard/${resellerId}?year=${Year}&month=${Month}&user=${User}`;
    const res = await apiLink(link);
    dispatch(getChartSuccess(res.data));
  } catch (err) {
    console.log("Charts error: ", err);
  }
};

//Areas

// export const getArea = async (dispatch, resellerId) => {
//   try {
//     const res = await apiLink.get(`/reseller/area/${resellerId}`);
//     dispatch(FetchAreaSuccess(res.data));
//   } catch (error) {
//     console.log(error.message);
//   }
// };

// Collector
export const getCollector = async (dispatch, resellerId) => {
  try {
    const res = await apiLink.get(`/reseller/collector/${resellerId}`);
    dispatch(getCollectorSuccess(res.data));
  } catch (error) {
    toast.error(error.response?.data.message);
  }
};

export const addCollector = async (dispatch, data, setIsLoading, setShow) => {
  setIsLoading(true);
  try {
    const res = await apiLink.post(`reseller/collector/${data.reseller}`, data);
    dispatch(addCollectorSuccess(res.data));
    setShow(false);
    langMessage(
      "success",
      "কালেক্টর সংযুক্ত সফল হয়েছে!",
      "Collector Added Successfully"
    );
  } catch (err) {
    if (err.response) {
      toast.error(err.response.data.message);
    }
  }
  setIsLoading(false);
};

export const editCollector = async (dispatch, data, setIsLoading, setShow) => {
  const { resellerId, collectorId, ...rest } = data;
  setIsLoading(true);
  try {
    const res = await apiLink.patch(
      `reseller/collector/${resellerId}/${collectorId}`,
      rest
    );
    dispatch(editCollectorSuccess(res.data));

    setShow(false);
    langMessage(
      "success",
      "কালেক্টর এডিট সফল হয়েছে!",
      "Collector Updated Successfully!"
    );
  } catch (err) {
    if (err.response) {
      toast.error(err.response.data.message);
    }
  }
  setIsLoading(false);
};

export const deleteCollector = async (dispatch, ids, setIsDeleting) => {
  setIsDeleting(true);
  try {
    await apiLink.delete(
      `reseller/collector/${ids.resellerId}/${ids.collectorId}`
    );
    dispatch(deleteCollectorSuccess(ids.collectorId));
    setIsDeleting(false);
    langMessage(
      "success",
      "কালেক্টর ডিলিট সফল হয়েছে!",
      "Collector Deleted Successfully!"
    );
  } catch (err) {
    if (err.response) {
      setIsDeleting(false);
      toast.error(err.response.data.message);
    }
  }
};

//Customers
export const getCustomer = async (dispatch, reseller, setIsloading) => {
  setIsloading(true);
  try {
    const res = await apiLink.get(`/reseller/customer/${reseller}`);
    dispatch(getCustomerSuccess(res.data));
    setIsloading(false);
  } catch (error) {
    console.log(error.message);
    setIsloading(false);
  }
};

export const addCustomer = async (
  dispatch,
  data,
  setIsloading,
  resetForm,
  setShow
) => {
  setIsloading(true);
  try {
    const res = await apiLink.post("/reseller/customer", data);
    dispatch(addCustomerSuccess(res.data));
    setIsloading(false);
    setShow(false);
    langMessage(
      "success",
      "কাস্টমার সংযুক্ত সফল হয়েছে!",
      "Customer Added Successfully"
    );
    document.querySelector("#customerModal").click();
    resetForm();
  } catch (err) {
    if (err.response) {
      setIsloading(false);
      toast.error(err.response.data.message);
    }
  }
};

export const editCustomer = async (
  dispatch,
  data,
  setIsloading,
  setShow,
  status
) => {
  setIsloading(true);
  const { singleCustomerID, reseller, ...sendingData } = data;
  try {
    const res = await apiLink.patch(
      `/reseller/customer/${reseller}/${singleCustomerID}`,
      sendingData
    );
    dispatch(editCustomerSuccess(res.data));

    if (status === "auto") {
      langMessage(
        "success",
        "কাস্টমার আটো ডিজেবল আপডেট সফল হয়েছে",
        "Customer Auto Disable Updated Successfully"
      );
    } else if (status === "status") {
      langMessage(
        "success",
        "কাস্টমার স্টাটাস আপডেট সফল হয়েছে",
        "Customer Status Updated Successfully"
      );
    } else {
      langMessage(
        "success",
        "কাস্টমার এডিট সফল হয়েছে",
        "Customer Updated Successfully"
      );
    }
    setShow(false);
  } catch (err) {
    if (err.response) {
      toast.error(err.response.data.message);
    }
  }
  setIsloading(false);
};

export const deleteResellerCustomer = async (
  dispatch,
  data,
  setIsLoading,
  setShow
) => {
  setIsLoading(true);
  try {
    await apiLink.delete(
      `/reseller/customer/${data.reseller}/${data.customerID}?removeFromMikrotik=${data.mikrotik}`
    );
    // delete customer from owner
    if (data.userType === "pppoe") {
      dispatch(deleteCustomerSuccess(data.customerID));
    } else {
      dispatch(deleteStaticCustomerSuccess(data.customerID));
    }

    setShow(false);
    langMessage(
      "success",
      "কাস্টমার ডিলিট সফল হয়েছে!",
      "Customer Deleted Successfully"
    );
  } catch (err) {
    if (err.response) {
      toast.error(err.response.data.message);
    }
  }
  setIsLoading(false);
};

// add reseller customer
export const addResellerStaticCustomer = async (
  dispatch,
  data,
  setIsloading,
  resetForm,
  setShow
) => {
  setIsloading(true);
  try {
    const res = await apiLink.post("reseller/create-static-customer/", data);
    dispatch(addStaticCustomerSuccess(res.data.customer));
    setShow(false);
    langMessage(
      "success",
      "কাস্টমার এড সফল হয়েছে",
      "Customer Added Successfully"
    );

    resetForm();
  } catch (error) {
    toast.error(error.response?.data?.message);
  }
  setIsloading(false);
};

// edit reseller customer
export const updateResellerStaticCustomer = async (
  customerId,
  resellerId,
  dispatch,
  data,
  setIsloading,
  status
) => {
  setIsloading(true);
  try {
    const res = await apiLink.patch(
      `reseller/update-static-customer/${resellerId}/${customerId}`,
      data
    );
    dispatch(editStaticCustomerSuccess(res.data.customer));

    if (status === "auto") {
      langMessage(
        "success",
        "কাস্টমার আটো ডিজেবল আপডেট সফল হয়েছে",
        "Customer Auto Disable Updated Successfully"
      );
    } else if (status === "status") {
      langMessage(
        "success",
        "কাস্টমার স্টাটাস আপডেট সফল হয়েছে",
        "Customer Status Updated Successfully"
      );
    } else {
      langMessage(
        "success",
        "কাস্টমার এডিট সফল হয়েছে",
        "Customer Updated Successfully"
      );
    }

    document.getElementById("resellerCustomerEdit").click();
  } catch (error) {
    toast.error(error.response?.data?.message);
  }
  setIsloading(false);
};

//Static Customers
export const getStaticActiveCustomer = async (
  dispatch,
  ispOwnerId,
  resellerId,
  mikrotikId,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await apiLink.get(
      `/reseller/active/static/${ispOwnerId}/${resellerId}/${mikrotikId}`
    );
    dispatch(getStaticCustomerActiveSuccess(res.data));
  } catch (error) {
    console.log(error.response.data.message);
  }
  setIsLoading(false);
};

//password update
export const passwordUpdate = async (data, setIsLoadingpass) => {
  setIsLoadingpass(true);

  try {
    await apiLink.post(`/auth/update-password`, data);
    setIsLoadingpass(false);
    langMessage(
      "success",
      "পাসওয়ার্ড আপডেট সফল হয়েছে!",
      "Password Updated Successfully"
    );
  } catch (error) {
    console.log(error.message);
    setIsLoadingpass(false);
    toast.error(error.response?.data.message);
  }
};

export const profileUpdate = async (dispatch, data, id, setIsLoading) => {
  setIsLoading(true);

  try {
    const res = await apiLink.patch(`/reseller/${id}`, data);
    // console.log(res.data);
    dispatch(updateProfile(res.data));
    setIsLoading(false);
    langMessage(
      "success",
      "প্রোফাইল আপডেট সফল হয়েছে!",
      "Profile Updated Successfully"
    );
  } catch (error) {
    setIsLoading(false);
    toast.error(error.response?.data.message);
  }
};

//get reseller info
export const resellerInfo = async (
  resellerId,
  dispatch,
  setCollectorResellerInfo
) => {
  try {
    const res = await apiLink.get(`/reseller/${resellerId}`);
    dispatch(getResellerProfile(res.data));
  } catch (error) {
    console.log(error);
  }
};

// get reseller package rate
export const getResellerPackageRate = async (
  resellerId,
  packageId,
  setPackageRate
) => {
  try {
    const res = await apiLink.get(
      `/reseller/package/rate/${resellerId}/${packageId}`
    );
    setPackageRate(res.data);
  } catch (error) {
    toast.error(error.response?.data.message);
  }
};

//Bill
export const billCollect = async (
  dispatch,
  billData,
  paidConnectionFee,
  setLoading,
  resetForm,
  setResponseData,
  setTest,
  status,
  setShow
) => {
  setLoading(true);
  try {
    const res = await apiLink.post("/reseller/monthlyBill", billData);

    if (status === "pppoe") {
      dispatch(updateBalance(res.data));
    } else if (status === "static") {
      dispatch(updateBalanceStaticCustomer(res.data));
    }

    // customer profile connection fee update after bill collect
    if (res.data.billType === "connectionFee") {
      const connectionFeeDue = paidConnectionFee + res.data.amount;
      dispatch(getCustomerConnectionFeeDue(connectionFeeDue));
    }

    setResponseData(res.data);
    setTest(true);
    langMessage(
      "success",
      "রিচার্জ সফল হয়েছে",
      "Bill Acceptance is Successful."
    );

    setShow(false);
    resetForm();
  } catch (error) {
    toast.error(error.response?.data.message);
  }
  setLoading(false);
};

export const addDeposit = async (dispatch, data, setLoading) => {
  setLoading(true);

  try {
    const res = await apiLink.post(`/deposit`, data);
    dispatch(addDepositSucces(res.data));
    langMessage(
      "success",
      "ডিপোজিট প্রদান সফল হয়েছে!",
      "Deposit Payment Successfully"
    );
  } catch (error) {
    toast.error(error.response?.data.message);
  }
  setLoading(false);
};

//balance

export const getTotalbal = async (dispatch, setLoading) => {
  setLoading(true);
  try {
    const res = await apiLink.get(`bill/monthlyBill/balance`);
    dispatch(getTotalBalanceSuccess(res.data));
    setLoading(false);
  } catch (error) {
    setLoading(false);
    toast.error(error.response?.data.message);
  }
};

export const getDeposit = async (dispatch, setResellerPageLoader) => {
  setResellerPageLoader(true);
  try {
    const res = await apiLink.get(`/deposit`);
    dispatch(getmyDepositSucces(res.data));
  } catch (error) {
    console.log(error.response?.data.message);
  }
  setResellerPageLoader(false);
};

export const getDepositforReseller = async (
  dispatch,
  resellerId,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    const res = await apiLink.get(`/deposit/reseller/collector/${resellerId}`);
    dispatch(getDepositSuccess(res.data));
  } catch (error) {
    console.log(error);
  }
  setIsLoading(false);
};

export const depositAcceptReject = async (
  dispatch,
  status,
  id,
  setAccLoading
) => {
  // console.log(status, id);
  setAccLoading(true);
  try {
    const res = await apiLink.patch(`/deposit/${id}`, { status: status });
    dispatch(updateDepositSuccess(res.data));
    setAccLoading(false);
    if (res.data.status === "accepted") {
      langMessage(
        "success",
        "ডিপোজিট গ্রহণ সফল হয়েছে",
        "Deposti Received Successfully"
      );
    } else if (res.data.status === "rejected") {
      langMessage(
        "success",
        "ডিপোজিট বাতিল সফল হয়েছে",
        "Deposit Cancellation Successfully"
      );
    }
  } catch (error) {
    setAccLoading(false);

    toast.error(error.response?.data.message);
  }
};

export const getAllBills = async (
  dispatch,
  resellerId,
  year,
  month,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await apiLink.get(
      `/reseller/bills/${resellerId}?year=${year}&month=${month}`
    );
    dispatch(getAllBillsSuccess(res.data));
  } catch (error) {
    toast.error(error.response?.data.message);
  }
  setIsLoading(false);
};

//my deposit

export const getMyDeposit = async (dispatch) => {
  try {
    const res = await apiLink.get("/deposit");
    dispatch(getmyDepositSucces(res.data));
  } catch (error) {
    console.log(error?.response?.data.message);
  }
};

//Collector Bills

export const getCollectorBill = async (dispatch) => {
  try {
    const res = await apiLink.get("/bill/monthlyBill");
    dispatch(getCollectorBills(res.data));
  } catch (error) {
    console.log(error.response?.data.message);
  }
};

//sub areas for reseller

export const getSubAreas = async (dispatch, resellerId) => {
  try {
    const res = await apiLink.get(`/reseller/subArea/${resellerId}`);
    dispatch(FetchAreaSuccess(res.data));
  } catch (error) {
    console.log(error);
  }
};

// mikrotiks

export const getMikrotik = async (dispatch, resellerId) => {
  try {
    const res = await apiLink.get(`/reseller/mikrotik/${resellerId}`);

    dispatch(getMikrotikSuccess(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const fetchpppoePackage = async (dispatch, IDs) => {
  try {
    const res = await apiLink({
      method: "GET",
      url: `/mikrotik/ppp/package/${IDs.mikrotikId}`,
    });
    dispatch(getpppoePackageSuccess(res.data));
  } catch (error) {
    console.log(error.response);
  }
};

export const withMtkPackage = async (dispatch, resellerId) => {
  try {
    const res = await apiLink({
      method: "GET",
      url: `/reseller/package/${resellerId}`,
    });
    dispatch(getpppoePackageSuccess(res.data.packages));
  } catch (error) {
    console.log(error.response);
  }
};

export const rechargeHistoryfuncR = async (dispatch, resellerId) => {
  try {
    const res = await apiLink.get(`/reseller/recharge/${resellerId}`);
    dispatch(getAllRechargeHistory(res.data));
  } catch (error) {
    console.log(error.response?.data.message);
  }
};

export const getMikrotikPackages = async (dispatch, ispOwnerId) => {
  try {
    const res = await apiLink.get(
      "/mikrotik/ppp/mikrotik/package/" + ispOwnerId
    );
    dispatch(getAllMikrotikPakages(res.data));
  } catch (error) {
    console.log(error.response?.data.message);
  }
};

//static customer

export const getStaticCustomerApi = async (
  dispatch,
  reseller,
  setIsloading
) => {
  setIsloading(true);
  try {
    const res = await apiLink.get(`/reseller/static/customer/${reseller}`);
    dispatch(getStaticCustomerSuccess(res.data));
  } catch (error) {
    console.log(error.message);
  }
  setIsloading(false);
};

// get support
export const getResellerNetFeeSupport = async (
  dispatch,
  setIsLoading,
  resellerId
) => {
  setIsLoading(true);
  try {
    const res = await apiLink.get(`reseller/netFee/support/${resellerId}`);
    dispatch(getResellerSupport(res.data));
  } catch (error) {
    console.log(error.response);
  }
  setIsLoading(false);
};

// post support
export const postResellerNetFeeSupport = async (
  dispatch,
  setIsLoading,
  supportData
) => {
  setIsLoading(true);
  try {
    const res = await apiLink.post(
      `reseller/create/netFee-support`,
      supportData
    );
    dispatch(addResellerSupport(res.data));
    langMessage(
      "success",
      "নেটফি সাপর্ট অ্যাড সফল হয়েছে",
      "NetFee Support Add Successfully"
    );
    document.querySelector("#resellerSupportAdd").click();
  } catch (error) {
    console.log(error.response);
  }
  setIsLoading(false);
};

//put support
export const putResellerNetFeeSupport = async (
  dispatch,
  setIsLoading,
  editSupport
) => {
  setIsLoading(true);

  try {
    const res = await apiLink.patch(
      `reseller/netFee/support-edit/${editSupport.id}`,
      editSupport
    );
    document.querySelector("#resellerSupportEditId").click();
    dispatch(updateResellerSupport(res.data));
    langMessage(
      "success",
      "নেটফি সাপর্ট আপডেট সফল হয়েছে",
      "NetFee Support Update Successfully"
    );
  } catch (error) {
    console.log(error.response);
  }

  setIsLoading(false);
};

// get reseller customer change request
export const getChangePackage = async (dispatch, resellerId, setIsLoading) => {
  setIsLoading(true);

  try {
    const res = await apiLink.get(
      `reseller/customer/package/changes/data?resellerId=${resellerId}`
    );
    dispatch(getResellerChangePackageRequest(res.data));
  } catch (error) {
    console.log(error.response);
  }

  setIsLoading(false);
};

//accepted package
export const acceptedChangePackage = async (dispatch, data, setIsLoading) => {
  setIsLoading(true);

  try {
    const res = await apiLink.patch(`reseller/accept/package/change`, data);
    dispatch(updateResellerChangePackageRequest(res.data));
  } catch (error) {
    console.log(error.response);
  }

  setIsLoading(false);
};

//reject package
export const rejectChangePackage = async (dispatch, data, setIsLoading) => {
  setIsLoading(true);

  try {
    const res = await apiLink.patch(`reseller/reject/package/change`, data);
    dispatch(updateResellerChangePackageRequest(res.data));
  } catch (error) {
    console.log(error.response);
  }

  setIsLoading(false);
};

//get reseller online customer payment balance
export const getOnlineBalance = async (
  resellerId,
  year,
  month,
  setOnlineBalance
) => {
  try {
    const res = await apiLink.get(
      `reseller/customer-online-payment/${resellerId}?year=${year}&month=${month}`
    );
    setOnlineBalance(res.data);
  } catch (error) {
    toast.error(error.response?.data.message);
  }
};

// reseller online customer payment withdraw balance
export const postOnlinePayment = async (dispatch, data, setIsLoading) => {
  console.log(data);
  setIsLoading(true);
  try {
    const res = await apiLink.post(
      `reseller/payment/withdraw/${data?.reseller}`,
      data
    );
    dispatch(postWithdrawBalance(res.data));
  } catch (error) {
    toast.error(error.response?.data.message);
  }
  setIsLoading(false);
};

// get reseller payment withdraw report
export const getPaymentWithdrawReport = async (
  dispatch,
  resellerId,
  year,
  month,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await apiLink.get(
      `reseller/payment/withdraw/${resellerId}?year=${year}&month=${month}`
    );
    dispatch(getWithdrawBalance(res.data));
  } catch (error) {
    toast.error(error.response?.data.message);
  }
  setIsLoading(false);
};

// get reseller payment withdraw report
export const getResellerUsers = async (dispatch, resellerId, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await apiLink.get(`reseller/staffs/${resellerId}`);
    dispatch(getResellerUsersData(res.data));
  } catch (error) {
    toast.error(error.response?.data.message);
  }
  setIsLoading(false);
};
