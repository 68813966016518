import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";

// internal imports
import { ispOwnerPermission } from "../ispOwnerPermission/Permission";
import { updateOwner } from "../../../features/apiCallAdmin";
import Loader from "../../../components/common/Loader";
import ComponentCustomModal from "../../../components/common/customModal/ComponentCustomModal";
import InformationTooltip from "../../../components/common/tooltipInformation/InformationTooltip";

const Permissions = ({ show, setShow, ownerId }) => {
  const dispatch = useDispatch();
  // get isp owner
  let ispOwners = useSelector((state) => state.admin?.ispOwners);

  // get single isp owner data
  const ownerData = ispOwners.find((item) => item.id === ownerId);

  // loading state
  const [isLoading, setIsLoading] = useState(false);

  // permission state
  const [permissions, setPermissions] = useState([]);

  // get netFee language
  const netFeeLang = localStorage.getItem("netFee:lang");

  // set permission in state
  useEffect(() => {
    if (ownerData) {
      setPermissions(ispOwnerPermission(ownerData?.bpSettings));
    }
  }, [ownerData, ispOwners]);

  // handle change
  const handleChange = (e) => {
    const { name, checked } = e.target;
    let temp = permissions.map((val) =>
      val.value === name ? { ...val, isChecked: checked } : val
    );
    setPermissions(temp);
  };

  // submit handler
  const permissionHandler = () => {
    let temp = {};
    permissions.forEach((val) => {
      temp[val.value] = val.isChecked;
    });
    const updatePermission = {
      ...ownerData?.bpSettings,
      ...temp,
    };

    const sendingData = {
      ...ownerData,
      bpSettings: updatePermission,
    };

    updateOwner(ownerId, sendingData, setIsLoading, dispatch, setShow);
  };

  return (
    <>
      <ComponentCustomModal
        show={show}
        setShow={setShow}
        centered={false}
        size="xl"
        header={
          <div className="d-flex">
            <h4 className="text-success me-3">Change Permissions : </h4>
            <h5 className="text-secondary me-3">
              NetFee Id:
              <span className="text-success">{ownerData?.netFeeId}</span>
            </h5>
            <h5 className="text-secondary me-3">
              Mobile:
              <span className="text-success">{ownerData?.mobile}</span>
            </h5>
            <h5 className="text-secondary me-3">
              Company:
              <span className="text-success">{ownerData?.company}</span>
            </h5>
            <h5 className="text-secondary me-3">
              Name:
              <span className="text-success">{ownerData?.name}</span>
            </h5>
          </div>
        }
        footer={
          <div className="displayGrid1 float-end mt-4">
            <button
              type="button"
              className="btn btn-secondary"
              disabled={isLoading}
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={permissionHandler}
              className="btn btn-success customBtn"
            >
              {isLoading ? <Loader /> : "Save"}
            </button>
          </div>
        }
      >
        <div className="displayGrid3">
          {permissions?.map((val, key) => (
            <div
              className="CheckboxContainer justify-content-between"
              key={key}
            >
              <div className="d-flex">
                <input
                  type="checkbox"
                  className="CheckBox"
                  name={val.value}
                  checked={val.isChecked}
                  onChange={handleChange}
                  id={val.value + key}
                />
                <label htmlFor={val.value + key} className="checkboxLabel ">
                  {val.label}
                </label>
              </div>

              {/* there is information to grant permission tooltip */}
              {val?.info && <InformationTooltip data={val} />}
            </div>
          ))}
        </div>
      </ComponentCustomModal>
    </>
  );
};

export default Permissions;
